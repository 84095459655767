import React from "react"  
import Card from "./card"

const Posts = ({ posts }) => {  

  return (
	<div>
	{posts.map((post,i) => {
	    return (
		 <Card post={post} key={`post__${post.node.strapiId}`} />
		)
	})}
	</div>
  )
}

export default Posts
