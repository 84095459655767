import React from "react"  
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import Moment from "react-moment"
import Fixedimage from "./fixedimage"

const Card = ({ post }) => {  
  return (
    <article className="post">
	<header>
		<div className="title">
			<h2><Link to={`/post/${post.node.slug}`}>{post.node.title}</Link></h2>
		</div>
		<div className="meta">
			<Moment format="MMM Do YYYY" className="published">{post.node.publish_date}</Moment>
{post.node.authors.map((author, i) => {
            return (
                <span className="author" key={author.first_name}><span className="name">{author.first_name} {author.last_name}</span><Fixedimage alt="Author Avatar" filename="avatar.jpg" /></span>
            )
          })}
		</div>
	</header>
<Link to={`/post/${post.node.slug}`} className="image featured"><img src={post.node.hero_image.publicURL} alt="" /></Link>
<div><ReactMarkdown source={post.node.intro} /></div>
								<footer>
									<ul className="actions">
										<li><Link to={`/post/${post.node.slug}`} className="button large">Continue Reading</Link></li>
									</ul>
									<ul className="stats">
										<li><Link to={`/category/${post.node.category.id}`}>{post.node.category.name}</Link></li>
									</ul>
								</footer>
    </article>
  )
}

export default Card  
