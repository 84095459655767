import React from "react"
import { graphql } from "gatsby"

import PostsComponent from "./posts"
import Layout from "./layout"
import SEO from "./seo"

export const query = graphql`  
  query Category($id: String) {
    posts: allStrapiPosts(sort: {fields: publish_date, order: DESC}, filter: {active: {eq: true},  category: { id: { eq: $id } } }) {
      edges {
        node {
          strapiId
          title
	  slug
          category {
            name
	    id
          }
          hero_image {
            publicURL
          }
	  authors {
		first_name
		last_name
		}
	  publish_date
	  intro
        }
      }
    }
    category: strapiCategories(strapiId: { eq: $id }) {
      name
    }
  }
`

const Category = ({ data }) => {
    const posts = data.posts.edges
    const category = data.category.name

    return (
<>
       <SEO
        title="Category: {category}"
        description="nerdleader posts for the category {category}"
         />
     <div id="wrapper">
       <Layout>
            <div className="main">
                <h1>Category: {category}</h1>
                <PostsComponent posts={posts} />
            </div>
        </Layout>
    </div>
</>
    )
}

export default Category
